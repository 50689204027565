// import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../../hooks/use-api-hook-wrapper';

// import { GLOBAL_CONFIG } from '../../../config/config';
import { IPagination } from '../../../../types';
// import { getMetaTag, getRoleId } from '../../../utils';
// import { ROLE_TAG_ID_MAP } from '../../../common/constants';
import { ContentfulApiService } from '../../../../services/ContentfulApi.service';
import { getAssetIdsParams } from '../../../../common';
import { ContentfulTransformService } from '../../../../services/ContentfulTransform.service';
// import { DEFAULT_LANGUAGE_CODE } from '../../../../common/constants';

type Input = {
    pagination: IPagination;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const data = await ContentfulApiService.searchContent({
        contentType: 'learningCardSection',
        page: props.input.pagination.page,
        limit: props.input.pagination.perPage,
        sortBy: props.input.pagination.sortBy,
    });

    // console.log('===use-learning-card-section-hook===data==', data);

    const assetsResponse = await ContentfulApiService.getAssets({
        ...getAssetIdsParams(
            ContentfulTransformService.getLearningCardSectionAssets(data.items)
        ),
    });

    // console.log('====useLearningCardSectionHook===assetsResponse=', assetsResponse);

    const sections = ContentfulTransformService.getLearningCardSections(
        assetsResponse,
        data,
    );

    // console.log('====useLearningCardSectionHook===learningCardSectionsObj=', sections);

    for (let i = 0; i < sections.length; ++i) {
        const section = sections[i];
        if (section.referencedTheme) {
            const linkedContentEntryId = section.referencedTheme.sys.id;
            const response = await ContentfulApiService.getEntry({
                entryId: linkedContentEntryId,
            });
            // console.log('===referencedTheme===response=', response);
            if (response) {
                section.referencedTheme = ContentfulTransformService.transformLearningCardTheme(undefined, response);
            }
        }
    }

    console.log('====learningCardSectionsObj===', sections);

    return {
        mapped: sections,
        raw: data
    };
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    console.log('==error==', error, '=exception==', exception);
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useLearningCardSectionHook = (
    pagination: IPagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
