import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import 'moment-timezone';
import { FlexGrid } from '../../../../components/FlexGrid';
import {
    Table,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
} from '../../../../components/Table';
import { Text } from '../../../../components/Text';
import { COLORS } from '../../../../styles/variables';
import { ArrowDownIcon, PlusIcon } from '../../../../assets/icons';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import { IconButton } from '../../../../components/IconButton';
import { useLearningCardSectionHook } from '../hooks/use-learning-card-section-hook';
import { Pagination } from '../../../../components/Pagination';
import { sortFunc } from '../../../../common';
import { LearningCardSectionModal } from './LearningCardSectionModal';
import { CreationService } from '../../../../services/Creation.service';
import {
    PLACEHOLDER_IMAGE,
} from '../../../../common/constants';
import {
    LearningCardSectionItemToEditModel,
    arrayElementsEqual,
    isStringRequiredMissing,
    roundDecimals,
} from '../../../../utils';
import { useLearningCardThemeHook } from '../../LearningCardTheme/hooks/use-learning-card-theme-hook';
import { updateModalAction } from '../../../../store/store.actions';
import { useDispatch } from 'react-redux';
import { ApiService } from '../../../../services/Api.service';
import { TableLoadingSpinner } from '../../../../components/TableLoadingSpinner';

const NewItemBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    font-size: 1rem;
`;

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};

    &:first-of-type {
        border-left: none;
    }
`;

const ImageContainer = styled(FlexGrid)`
    width: 100px;
    height: 64px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    img {
        object-fit: cover;
        height: 100%;
    }
`;

export const LearningCardSectionTable: React.FC = () => {
    const dispatch = useDispatch();
    const [current, setCurrent] = useState(null);
    const [update, setUpdate] = useState(false);
    const perPage = 5;
    const DEFAULT_SORT = {
        name: 0,
        languages: 0,
        linkedTheme: 0,
        status: 0,
    };
    const [sort, setSort] = useState<any>(DEFAULT_SORT);

    const location = useLocation() as any;
    const [page, setPage] = useState(location.state?.page || 1);

    const [{ isLoading, hookData: sectionsData }] = useLearningCardSectionHook(
        { page, perPage, sortBy: sort.name !== 0
            ? `${sort.name === 1 ? '' : '-'}fields.name`
            : sort.languages !== 0
            ? `${sort.languages === 1 ? '' : '-'}fields.languages`
            : sort.linkedTheme !== 0
            ? `${sort.linkedTheme === 1 ? '' : '-'}fields.referencedTheme`
            : sort.status !== 0
            ? `${sort.status === 1 ? '' : '-'}fields.status`
            : undefined 
        },
        [page, perPage, sort, update]
    );

    const [{ isLoading: isThemeLoading, hookData: themesData }] =
        useLearningCardThemeHook({}, [update]);

    const [createEditModal, setCreateEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [createEditSuccess, setCreateEditSuccess] = useState(null);
    const [createEditSectionDisabled, setCreateEditSectionDisabled] =
        useState(false);

    const [detailsModal, setDetailsModal] = useState(false);

    const [errorMsg, setErrorMsg] = useState(undefined);

    console.log('==sectionsData==', sectionsData);

    const [sectionsDashboardStats, setSectionsDashboardStats] = useState({
        totalSections: 0,
        sectionsActive: 0,
        sectionsExpired: 0,
        pointsDisbursed: 0,
        totalParticipants: 0,
    });

    useEffect(() => {
        ApiService.getLearningCardSectionsDashboardStats()
            .then((response) => {
                // {
                //     "totalSections": 5,
                //     "sectionsActive": 5,
                //     "sectionsExpired": 0,
                //     "pointsDisbursed": 50,
                //     "totalParticipants": 2
                // }
                const data = response.data;
                setSectionsDashboardStats(data);
                console.log('==getSectionsDashboardStats==', data);
            })
            .catch((err) =>
                console.error(
                    '===Failed to fetch learning card sections dashboard stats===',
                    err
                )
            );
    }, []);

    const sections = sectionsData?.mapped ?? [];
    const total = sectionsData?.raw?.total || 0;

    console.log('==sections==', sections);
    console.log('==current==', current);

    return (
        <>
            {isLoading && <TableLoadingSpinner overlay />}
            <FlexGrid
                width="100%"
                justifyContent="space-between"
                alignItems="center"
            >
                <Text font="OS" fontSize="2.25rem" uppercase>
                    Learning card section
                </Text>
                <NewItemBtn
                    negative
                    title="New Learning Card Section"
                    icon={<PlusIcon />}
                    iconSlot="start"
                    onClick={() => {
                        setCurrent(null);
                        setCreateEditModal(true);
                    }}
                />
            </FlexGrid>
            <LearningCardSectionModal
                title={current ? 'Edit a section' : 'Add new section'}
                onClose={() => {
                    if (createEditSuccess) {
                        setUpdate(!update);
                    }
                    setCreateEditModal(false);
                }}
                open={createEditModal}
                success={createEditSuccess}
                setSuccess={setCreateEditSuccess}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                successContentText={
                    current
                        ? 'The section has been updated successfully.'
                        : 'New section has been added successfully.'
                }
                disabled={createEditSectionDisabled}
                params={{
                    themesData: themesData?.mapped,
                    section:
                        LearningCardSectionItemToEditModel(current),
                }}
                onConfirm={async (learningCardSection) => {
                    console.log(
                        '===learningCardSection==',
                        learningCardSection
                    );

                    setErrorMsg('');
                    const sectionLanguages =
                        learningCardSection.appLanguages;
                    const themeLanguages =
                        learningCardSection.content.referencedTheme
                            ?.language;
                    if (
                        !themeLanguages ||
                        !arrayElementsEqual(
                            themeLanguages,
                            sectionLanguages
                        )
                    ) {
                        setErrorMsg(
                            `The language and theme language "${
                                themeLanguages ?? [].join(', ')
                            }" should be same`
                        );
                        return;
                    }

                    // Check multi-language fields are filled
                    for (const language of sectionLanguages) {
                        if (
                            isStringRequiredMissing(
                                learningCardSection.content.headline[
                                    language
                                ]
                            )
                        ) {
                            setErrorMsg(
                                `Headline in ${language} is missing`
                            );
                            return;
                        }
                        if (
                            isStringRequiredMissing(
                                learningCardSection.content.description[
                                    language
                                ]
                            )
                        ) {
                            setErrorMsg(
                                `Description in ${language} is missing`
                            );
                            return;
                        }
                    }

                    setCreateEditSectionDisabled(true);
                    const isUpdate = !!current;

                    console.log(
                        '===learningCardSection==',
                        learningCardSection,
                        '===isUpdate==',
                        isUpdate
                    );

                    try {
                        const contentfulResult =
                            await CreationService.createUpdateLearningCardSection(
                                dispatch,
                                isUpdate,
                                true,
                                learningCardSection
                            );
                        console.log(
                            '==contentfulResult==',
                            contentfulResult
                        );
                        const entryId = contentfulResult?.sys?.id;
                        if (entryId) {
                            await ApiService.updateLearningCardSection(
                                entryId
                            );
                            setCreateEditSectionDisabled(false);
                            setCreateEditSuccess(true);
                        } else {
                            console.error(
                                '==CreationService.createUpdateLearningCardSection==, no contentfulResult.sys.id exists'
                            );
                        }
                    } catch (err) {
                        console.error(
                            '==CreationService.createUpdateLearningCardSection==',
                            err
                        );
                        dispatch(
                            updateModalAction({
                                open: true,
                                title: 'error',
                                content: `Failed to ${
                                    isUpdate ? 'update' : 'create'
                                } learning card section. ${err}. ${
                                    err?.config?.url
                                        ? `url: ${err?.config?.url}`
                                        : ''
                                }`,
                            })
                        );
                    } finally {
                        setCreateEditSectionDisabled(false);
                    }
                }}
            />
            <Modal
                open={deleteModal}
                header="Delete a section"
                onClose={() => setDeleteModal(false)}
                content={
                    <FlexGrid
                        direction="column"
                        width="100%"
                        gap="2em"
                        p="2em 0em"
                    >
                        <Text color={COLORS.darkGray}>
                            Are you sure you want to delete this
                            section?
                        </Text>
                        <Text color={COLORS.darkGray}>
                            <Text
                                color={COLORS.darkGray}
                                span
                                font="AR Bold"
                            >
                                Note:{' '}
                            </Text>
                            Deleting a section is a permanent action and
                            cannot be undone.
                        </Text>
                    </FlexGrid>
                }
                actions={
                    <FlexGrid alignItems="center">
                        <Button
                            onClick={() => {
                                CreationService.deleteLearningCardSection(
                                    dispatch,
                                    current
                                )
                                    .then(() => {
                                        setUpdate(!update);
                                        if (total % perPage === 1) {
                                            setPage(
                                                Math.max(page - 1, 1)
                                            );
                                        }
                                    })
                                    .catch(() => {
                                        alert(
                                            'Cannot delete published'
                                        );
                                    });
                                setDeleteModal(false);
                            }}
                            p="1.3em 2.5em"
                        >
                            DELETE
                        </Button>
                        <Button
                            onClick={() => setDeleteModal(false)}
                            negative
                            p="0.5em 1em"
                        >
                            CANCEL
                        </Button>
                    </FlexGrid>
                }
            />
            {current && (
                <Modal
                    open={detailsModal}
                    header={`${current.name} details`}
                    onClose={() => setDetailsModal(false)}
                    content={
                        <FlexGrid direction="column">
                            <Table>
                                <TableRow>
                                    <TableHead padding="2em 0em 0em 0em">
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            Number
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead padding="2em 0em 0em 0em">
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            Card
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead padding="2em 0em 0em 0em">
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            Starting Learners
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead padding="2em 0em 0em 0em">
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            Completed Learners
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead padding="2em 0em 0em 0em">
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            % Completion
                                        </FlexGrid>
                                    </TableHead>
                                </TableRow>
                                {(current.statsData && current.statsData.length > 0) ?
                                    current.statsData.map(
                                        (statData, index) => (
                                            <TableRow
                                                key={`stat-detail-${index}`}
                                            >
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {index + 1}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {statData.section ??
                                                            ''}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {statData.startingUsers ??
                                                            0}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {statData.completedUsers ??
                                                            0}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {`${roundDecimals(
                                                            statData.percentCompletion ??
                                                                0,
                                                            2
                                                        )}%`}
                                                    </Text>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    ) : <TableRow
                                    key={`stat-detail-empty`}
                                >
                                    <TableCell
                                        verticalAlign="top"
                                        width="250px"
                                    >
                                        <Text
                                            fontSize="0.875rem"
                                            workBreakAll
                                        >
                                            {''}
                                        </Text>
                                    </TableCell>
                                    <TableCell
                                        verticalAlign="top"
                                        width="250px"
                                    >
                                        <Text
                                            fontSize="0.875rem"
                                            workBreakAll
                                        >
                                            {''}
                                        </Text>
                                    </TableCell>
                                    <TableCell
                                        verticalAlign="top"
                                        width="250px"
                                    >
                                        <Text
                                            fontSize="0.875rem"
                                            workBreakAll
                                        >
                                            {''}
                                        </Text>
                                    </TableCell>
                                    <TableCell
                                        verticalAlign="top"
                                        width="250px"
                                    >
                                        <Text
                                            fontSize="0.875rem"
                                            workBreakAll
                                        >
                                            {''}
                                        </Text>
                                    </TableCell>
                                    <TableCell
                                        verticalAlign="top"
                                        width="250px"
                                    >
                                        <Text
                                            fontSize="0.875rem"
                                            workBreakAll
                                        >
                                            {''}
                                        </Text>
                                    </TableCell>
                                </TableRow>}
                            </Table>
                        </FlexGrid>
                    }
                />
            )}
            <Table>
                <TableRow>
                    <TableHead
                        padding="2em 0em 0em 0em"
                        borderBottomDisabled
                    >
                        <FlexGrid alignItems="center" gap="0.8em">
                            Total Sections
                        </FlexGrid>
                    </TableHead>
                    <TableHead
                        padding="2em 0em 0em 0em"
                        borderBottomDisabled
                    >
                        <FlexGrid alignItems="center" gap="0.8em">
                            Total Learners (Participants)
                        </FlexGrid>
                    </TableHead>
                    <TableHead
                        padding="2em 0em 0em 0em"
                        borderBottomDisabled
                    >
                        <FlexGrid alignItems="center" gap="0.8em">
                            Total Active Sections
                        </FlexGrid>
                    </TableHead>
                    <TableHead
                        padding="2em 0em 0em 0em"
                        borderBottomDisabled
                    >
                        <FlexGrid alignItems="center" gap="0.8em">
                            Total Expired Sections
                        </FlexGrid>
                    </TableHead>
                    <TableHead
                        padding="2em 0em 0em 0em"
                        borderBottomDisabled
                    >
                        <FlexGrid alignItems="center" gap="0.8em">
                            Point Disbursed
                        </FlexGrid>
                    </TableHead>
                </TableRow>
                <TableRow>
                    <TableCell
                        padding="0em 0em 0em 0em"
                        borderBottomDisabled
                        verticalAlign="top"
                        width="250px"
                    >
                        <Text
                            font="OS"
                            fontSize="1.5rem"
                            color={COLORS.black}
                        >
                            {sectionsDashboardStats.totalSections}
                        </Text>
                    </TableCell>
                    <TableCell
                        padding="0em 0em 0em 0em"
                        borderBottomDisabled
                        verticalAlign="top"
                        width="250px"
                    >
                        <Text
                            font="OS"
                            fontSize="1.5rem"
                            color={COLORS.black}
                        >
                            {sectionsDashboardStats.totalParticipants}
                        </Text>
                    </TableCell>
                    <TableCell
                        padding="0em 0em 0em 0em"
                        borderBottomDisabled
                        verticalAlign="top"
                        width="250px"
                    >
                        <Text
                            font="OS"
                            fontSize="1.5rem"
                            color={COLORS.black}
                        >
                            {sectionsDashboardStats.sectionsActive}
                        </Text>
                    </TableCell>
                    <TableCell
                        padding="0em 0em 0em 0em"
                        borderBottomDisabled
                        verticalAlign="top"
                        width="250px"
                    >
                        <Text
                            font="OS"
                            fontSize="1.5rem"
                            color={COLORS.black}
                        >
                            {sectionsDashboardStats.sectionsExpired}
                        </Text>
                    </TableCell>
                    <TableCell
                        padding="0em 0em 0em 0em"
                        borderBottomDisabled
                        verticalAlign="top"
                        width="250px"
                    >
                        <Text
                            font="OS"
                            fontSize="1.5rem"
                            color={COLORS.black}
                        >
                            {`${sectionsDashboardStats.pointsDisbursed} pts`}
                        </Text>
                    </TableCell>
                </TableRow>
            </Table>
            <Table>
                <TableRow>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Name
                            {sort.name === 0 && (
                                <IconButton
                                    negative
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            name: 1,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.name === 1 && (
                                <IconButton
                                    negative
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            name: -1,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.name === -1 && (
                                <IconButton
                                    negative
                                    transform="rotate(180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            name: 0,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Languages
                            {sort.languages === 0 && (
                                <IconButton
                                    negative
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            languages: 1,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.languages === 1 && (
                                <IconButton
                                    negative
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            languages: -1,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.languages === -1 && (
                                <IconButton
                                    negative
                                    transform="rotate(180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            languages: 0,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Linked Module
                            {sort.linkedTheme === 0 && (
                                <IconButton
                                    negative
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            linkedTheme: 1,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.linkedTheme === 1 && (
                                <IconButton
                                    negative
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            linkedTheme: -1,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.linkedTheme === -1 && (
                                <IconButton
                                    negative
                                    transform="rotate(180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            linkedTheme: 0,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Status
                            {sort.status === 0 && (
                                <IconButton
                                    negative
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            status: 1,
                                        });
                                    }}
                                    transform="rotate(-90deg)"
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.status === 1 && (
                                <IconButton
                                    negative
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            status: -1,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.status === -1 && (
                                <IconButton
                                    negative
                                    transform="rotate(180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            status: 0,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>Actions</TableHead>
                </TableRow>
                {sections.map((section, key) => {
                    const url = section.image;
                    console.log(
                        '==section.referencedTheme==',
                        section.referencedTheme
                    );
                    return (
                        <TableRow key={key}>
                            <TableCell>
                                <FlexGrid gap="1em" alignItems="center">
                                    <ImageContainer>
                                        <img
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            src={
                                                url
                                                    ? `https:${url}`
                                                    : PLACEHOLDER_IMAGE
                                            }
                                            alt="section thumbnail"
                                        />
                                    </ImageContainer>
                                    <Text
                                        link
                                        fontSize="0.875rem"
                                        color={COLORS.green}
                                    >
                                        {section?.name}
                                    </Text>
                                </FlexGrid>
                            </TableCell>
                            <TableCell>
                                {section?.language?.join(', ')}
                            </TableCell>
                            <TableCell>
                                {section?.referencedTheme ? (
                                    <FlexGrid
                                        alignItems="center"
                                        gap="0.5em"
                                    >
                                        <Text
                                            color={COLORS.green}
                                            fontSize="0.875rem"
                                            workBreakAll
                                        >
                                            {
                                                section?.referencedTheme
                                                    .name
                                            }
                                        </Text>
                                    </FlexGrid>
                                ) : (
                                    ''
                                )}
                            </TableCell>
                            <TableCell>{section?.status}</TableCell>
                            <TableCell>
                                <FlexGrid alignItems="center">
                                    <TableActionBtn
                                        onClick={async () => {
                                            setDetailsModal(true);
                                            const entryId = section.id;
                                            const statsResponse =
                                                await ApiService.getSingleLearningCardSectionStats(
                                                    entryId
                                                );
                                            const statsData =
                                                statsResponse?.data;
                                            // [
                                            //     {
                                            //         "entryId": "75thx7aRcItZQUaWRui4hs",
                                            //         "section": "Test card 1 S 1 M 2",
                                            //         "startingUsers": 1,
                                            //         "completedUsers": 1,
                                            //         "percentCompletion": 100
                                            //     },
                                            //     {
                                            //         "entryId": "5dNfw71Jv4OK13Z85wSXPQ",
                                            //         "section": "Test learning card incomplete",
                                            //         "startingUsers": 1,
                                            //         "completedUsers": 0,
                                            //         "percentCompletion": null
                                            //     },
                                            //     {
                                            //         "entryId": "gQ5T8wPrdttcbivWn6t8O",
                                            //         "section": "Learning card video test",
                                            //         "startingUsers": 2,
                                            //         "completedUsers": 1,
                                            //         "percentCompletion": 50
                                            //     }
                                            // ]
                                            // console.log('==statsData==', statsData);
                                            section.statsData =
                                                statsData ?? [];
                                            setCurrent(section);
                                        }}
                                        negative
                                    >
                                        Stats
                                    </TableActionBtn>
                                    <TableActionBtn
                                        onClick={() => {
                                            console.log(
                                                '==edit==section==',
                                                section
                                            );
                                            setCurrent(section);
                                            setCreateEditModal(true);
                                        }}
                                        negative
                                    >
                                        Edit
                                    </TableActionBtn>
                                    <TableActionBtn
                                        onClick={() => {
                                            const cards = section.cards;
                                            // console.log('===cards===', cards);
                                            if (
                                                cards &&
                                                cards.length > 0
                                            ) {
                                                dispatch(
                                                    updateModalAction({
                                                        open: true,
                                                        title: 'error',
                                                        content: `You can't delete this section because it has ${
                                                            cards.length
                                                        } learning ${
                                                            cards.length ===
                                                            1
                                                                ? 'card'
                                                                : 'cards'
                                                        }.`,
                                                    })
                                                );
                                                return;
                                            }
                                            setCurrent(section);
                                            setDeleteModal(true);
                                        }}
                                        negative
                                    >
                                        Remove
                                    </TableActionBtn>
                                </FlexGrid>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </Table>
            <Pagination
                page={page}
                perPage={perPage}
                total={total}
                setPage={(v) => {
                    setPage(v);
                }}
            />
        </>
    );
};
