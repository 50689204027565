import React from 'react';
import styled, { keyframes } from 'styled-components';
import { COLORS } from '../styles/variables';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerWrapper = styled.div<{ overlay?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
    width: 100%;
    ${props => props.overlay && `
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8);
        z-index: 1000;
    `}
`;

const Spinner = styled.div`
    width: 40px;
    height: 40px;
    border: 3px solid ${COLORS.lightGray};
    border-top: 3px solid ${COLORS.green};
    border-radius: 50%;
    animation: ${spin} 1s linear infinite;
`;

interface Props {
    className?: string;
    overlay?: boolean;
}

export const TableLoadingSpinner: React.FC<Props> = ({ className, overlay }) => {
    return (
        <SpinnerWrapper className={className} overlay={overlay}>
            <Spinner />
        </SpinnerWrapper>
    );
}; 