// import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../../hooks/use-api-hook-wrapper';

// import { GLOBAL_CONFIG } from '../../../config/config';
import { IPagination } from '../../../../types';
// import { getMetaTag, getRoleId } from '../../../utils';
// import { ROLE_TAG_ID_MAP } from '../../../common/constants';
import { ContentfulApiService } from '../../../../services/ContentfulApi.service';
import { getAssetIdsParams } from '../../../../common';
import { ContentfulTransformService } from '../../../../services/ContentfulTransform.service';

type Input = {
    pagination: IPagination;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const data = await ContentfulApiService.searchContent({
        contentType: 'learningCardTheme',
        page: props.input.pagination.page,
        limit: props.input.pagination.perPage,
        sortBy: props.input.pagination.sortBy,
    });

    const assetsResponse = await ContentfulApiService.getAssets({
        ...getAssetIdsParams(
            ContentfulTransformService.getLearningCardThemeAssets(data.items)
        ),
    });

    const learningCardThemesObj = {
        mapped: ContentfulTransformService.getLearningCardThemes(
            assetsResponse,
            data,
        ),
        raw: data
    };

    console.log('learningCardTheme', learningCardThemesObj);

    return learningCardThemesObj;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useLearningCardThemeHook = (
    pagination: IPagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
